import * as React from 'react';
import { css } from '@emotion/react';
import { Logo } from '../components/Logo';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

const IndexPage: React.FC = () => {
  return (
    <>
      <Header />
      <main
        css={css`
          padding: 3rem;
          flex-grow: 1;
          h1 {
            font-size: 4rem;
            font-weight: 500;
            margin: 0;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            @media only screen and (max-width: 50rem) {
              flex-direction: column;
            }
          `}
        >
          <Logo
            css={css`
              height: 10rem;
              width: fit-content;
              margin-right: 2rem;
              margin-bottom: 1rem;
            `}
          />
          <div
            css={css`
              flex-grow: 1;
              text-align: right;
            `}
          >
            <p
              css={css`
                font-size: 1.5em;
              `}
            >
              Software Development
              <br />
              Contracting &amp; Consultancy
            </p>
          </div>
        </div>
        <p>
          Green Chestnut provides a wide range of software development services, specialising in front-end web
          development using React, as well as back-end development using Node and Java. Some of the technologies we are
          experienced with include:
        </p>
        <ul>
          <li>React</li>
          <li>JavaScript &amp; TypeScript</li>
          <li>Unit Testing with Jest and Testing Library</li>
          <li>BDD using Cypress and Cucumber</li>
          <li>GraphQL &amp; REST APIs</li>
          <li>Headless CMS (Contentful, Payload, Kentico, etc.)</li>
          <li>Next.js &amp; Gatsby</li>
          <li>Accessibility</li>
          <li>Semantic HTML</li>
          <li>CSS, SASS, Tailwind, Styled Components, Emotion</li>
          <li>Material UI</li>
          <li>Node &amp; npm</li>
          <li>Express</li>
          <li>Azure</li>
          <li>TeamCity</li>
          <li>Netlify</li>
          <li>Semantic Release</li>
          <li>ESLint, Prettier</li>
          <li>Linux</li>
          <li>Docker</li>
          <li>Java</li>
          <li>Kotlin</li>
          <li>Spring</li>
          <li>Hibernate</li>
          <li>SQL &amp; NoSQL Databases</li>
        </ul>
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;

export const Head = () => {
  return <title>Green Chestnut</title>;
};
