import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { Logo } from './Logo';

export const Header: React.FC = () => {
  return (
    <header
      css={css`
        background: var(--gc-dark-green);
        display: flex;
        flex-direction: row;
        color: white;
        padding-left: 2rem;
        align-items: center;
        p {
          flex-grow: 1;
          text-align: right;
          font-size: 1rem;
          margin: 0;
          margin-right: 1rem;
          height: 4rem;
        }
        a {
          text-decoration: none;
          padding: 1.25rem 0.75rem;
          height: 4rem;
          display: inline-block;
          color: white;
          &:hover,
          &:focus {
            background: white;
            color: var(--gc-dark-green);
          }
        }
      `}
    >
      <Logo
        textColor1="white"
        textColor2="white"
        css={css`
          height: 2rem;
          width: auto;
          margin: 1rem;
        `}
      />
      <p>
        <Link to="/">Home</Link> <Link to="/portfolio">Portfolio</Link> <Link to="/contact">Contact Us</Link>
      </p>
    </header>
  );
};
