import { css } from '@emotion/react';
import { Logo } from './Logo';

export const Footer: React.FC = () => {
  return (
    <footer
      css={css`
        background-color: var(--gc-dark-green);
        color: white;
        padding: 3rem;
        display: flex;
        flex-direction: row;
        @media only screen and (max-width: 40rem) {
          flex-direction: column-reverse;
        }
        h1 {
          font-weight: 500;
        }
      `}
    >
      <div
        css={css`
          text-align: right;
          flex-grow: 1;
          font-size: 0.8rem;
          border-right: solid 0.125rem white;
          padding-right: 1rem;
          margin-right: 2rem;
          @media only screen and (max-width: 40rem) {
            text-align: left;
            border-right: none;
            padding-right: 0;
            margin-right: 0;
            padding-top: 1rem;
            border-top: solid 0.125rem white;
          }

          p {
            margin-top: 0;
            margin-bottom: 0;
          }
        `}
      >
        <p>
          <b>Green Chestnut Limited</b>
          <br />
          36 Dovecote Close
          <br />
          Coventry CV6 1PS
          <br />
          <br />
          +44 (0)24 7538 6978
          <br />
          +44 (0)7942 292152
          <br />
          <br />
          Registered in England &amp; Wales
          <br />
          Registration number 14193402
          <br />
          VAT registration number GB 419 9038 73
          <br />
          <br />
          Copyright © 2024 Green Chestnut Limited
        </p>
      </div>
      <Logo
        textColor1="white"
        textColor2="white"
        css={css`
          height: 6rem;
          width: fit-content;
          margin-bottom: 2rem;
        `}
      />
    </footer>
  );
};
