export const Logo: React.FC<{
  textColor1?: string;
  textColor2?: string;
  outerColor?: string;
  innerColor?: string;
  className?: string;
}> = ({
  textColor1 = 'var(--gc-green)',
  textColor2 = 'var(--gc-brown)',
  outerColor = 'var(--gc-green)',
  innerColor = 'var(--gc-brown)',
  className,
}) => {
  return (
    <svg
      className={className}
      width="198.68"
      height="113.38"
      viewBox="0 0 52.569 30"
      xmlns="http://www.w3.org/2000/svg"
      dangerouslySetInnerHTML={{
        __html: `<path transform="translate(-78.77 -133.67)" d="m93.77 133.67a15 15 0 0 0-15 15 15 15 0 0 0 15 15 15 15 0 0 0 15-15h-10a5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5z" fill="${outerColor}" fill-opacity="1" stroke-width="0"/>
<path transform="translate(-78.77 -133.67)" d="m93.77 138.67a10 10 0 0 0-10 10 10 10 0 0 0 10 10 10 10 0 0 0 7.068-2.933l-3.532-3.532a5 5 0 0 1-3.535 1.465 5 5 0 0 1-5-5 5 5 0 0 1 5-5z" fill="${innerColor}" stroke-width="0"/>
<text transform="translate(-78.77 -133.91)" x="95.96" y="139.34" font-family="Poppins" font-size="7.7163px" font-weight="500" letter-spacing="0" stroke-width="0" word-spacing="0" style="line-height:.9" xml:space="preserve"><tspan x="95.96" y="139.34" fill="${textColor1}" font-family="Poppins" font-size="7.7163px" font-weight="500" stroke-width="0" style="font-variant-caps:normal;font-variant-east-asian:normal;font-variant-ligatures:normal;font-variant-numeric:normal">Green</tspan><tspan x="95.96" y="146.502" fill="${textColor2}" style="font-variant-caps:normal;font-variant-east-asian:normal;font-variant-ligatures:normal;font-variant-numeric:normal">Chestnut</tspan></text>`,
      }}
    ></svg>
  );
};
